<template>
  <div class="home">
    <van-search
      v-model.trim="value"
      placeholder="请输入商品名称"
      @focus="focus"
      :show-action="isShowbtn"
      @search="onSearch"
      @cancel="onCancel"
      autofocus
    />
    <div
      class="popular-search"
      v-for="(item, index) in searchList"
      :key="index"
    >
      <div class="popular-search-title">
        <div>{{ item.name }}</div>
        <div v-if="item.isShow" @click="deleteHandle(index)">
          <van-icon name="delete-o" />
        </div>
      </div>
      <div class="popular-search-ul">
        <span v-for="v in item.children" :key="v.id" @click="searchResult(v)">{{
          v.name
        }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { Icon, Button, Search, Dialog } from "vant";
import { mapMutations } from "vuex";

export default {
  name: "SearchGood",
  data() {
    return {
      value: "",
      isShowbtn: false,
      searchList: [
        // {
        //     name: '热门搜索',
        //     isShow: false,
        //     children: [
        //         {
        //             id: 0,
        //             name: '优酷黄金会员'
        //         },
        //         {
        //             id: 1,
        //             name: '爱奇艺黄金会员'
        //         },
        //         {
        //             id: 2,
        //             name: 'Q币'
        //         },
        //         {
        //             id: 3,
        //             name: '京东E卡'
        //         },
        //         {
        //             id: 4,
        //             name: 'QQ黄钻会员月卡'
        //         },
        //         {
        //             id: 5,
        //             name: '腾讯黄金会员'
        //         },
        //         {
        //             id: 6,
        //             name: '芒果黄金会员'
        //         }
        //     ]
        // },
        {
          name: "历史搜索",
          isShow: true,
          children: [],
        },
      ],
    };
  },
  components: {
    [Icon.name]: Icon,
    [Button.name]: Button,
    [Search.name]: Search,
    [Dialog.name]: Dialog,
  },
  created() {
    document.title = "商品搜索";
    this.getHistorical();
  },
  mounted() {
    const input = document.getElementsByClassName("van-field__control")[0];
    input && input.focus();
  },
  methods: {
    ...mapMutations(["setProductName"]),

    focus() {
      this.isShowbtn = true;
    },

    searchResult(item) {
      this.setProductName(item.name);
      this.$router.push(`/mall/goods`);
    },
    deleteHandle() {
      Dialog.confirm({
        message: "确定要删除历史记录",
      })
        .then(() => {
          // on confirm
          localStorage.removeItem("historical");
          this.getHistorical();
        })
        .catch(() => {
          // on cancel
        });
    },
    onSearch(val) {
      this.setProductName(val);
      var arr = JSON.parse(localStorage.getItem("historical") || "[]");
      if (arr.length < 6) {
        if (val) {
          arr.push({
            name: val,
          });
        }
      } else {
        arr.shift();
        arr.push({
          name: val,
        });
      }
      arr = this.Es6duplicate(arr, "name");
      localStorage.setItem("historical", JSON.stringify(arr || {}));
      this.getHistorical();
      this.$router.push(`/mall/goods`);
    },
    onCancel() {
      console.log("取消");
      this.$router.go(-1); //返回上一层
    },
    getHistorical() {
      let arr = JSON.parse(localStorage.getItem("historical") || "[]");
      this.searchList[0].children = arr;
    },

    // 数组对象去重
    Es6duplicate(arr, type) {
      if (arr.length == 0) {
        return arr;
      } else {
        if (type) {
          var obj = {};
          var newArr = arr.reduce((cur, next) => {
            obj[next.name] ? "" : (obj[next.name] = true && cur.push(next));
            return cur;
          }, []);
          return newArr;
        } else {
          return Array.from(new Set(arr));
        }
      }
    },
  },
};
</script>

<style lang="less">
.home {
  height: 100%;
  background-color: #fff;

  .popular-search {
    padding: 0 0.4rem;

    .popular-search-title {
      display: flex;
      justify-content: space-between;
      font-size: 0.3rem;
      color: #333333;
      font-weight: 500;
      margin: 0.2rem 0;
    }

    .popular-search-ul {
      display: flex;
      flex-wrap: wrap;
      box-sizing: border-box;
      > span {
        background: #f9f9f9;
        border-radius: 0.04rem;
        font-size: 0.24rem;
        font-weight: 400;
        color: #666666;
        display: inline-block;
        padding: 0.1rem 0.28rem;
        margin-right: 0.2rem;
        margin-bottom: 0.2rem;
      }
    }
  }
}
</style>
